@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  background-color: #091827;
}

.App {
  font-family: "Arial", sans-serif;
}

h1 {
  margin-bottom: 1rem;
  color: #F6E7D8;
}

p {
  color: #F6E7D8;
  font-family: 'Montserrat', sans-serif;
  font-size: 21px;
}

@media only screen and (max-width: 991px) {
  p {
    color: #F6E7D8;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
  }
}

.btn-primary {
  font-family: 'Montserrat', sans-serif;
  background-color: #FF6A6A;
  border: none;
}

.btn-primary:hover {
  background-color: #FF6A6A;
}

.btn-primary:focus {
  --bs-btn-bg: #009595;
  --bs-btn-border-color: #009595;
  --bs-btn-active-color: #F6E7D8;
  --bs-btn-active-bg: #009595;
  --bs-btn-active-border-color: #009595;
  box-shadow: none;
  outline: none;
}


b {
  font-weight: 900;
}

.svg-container {
  width: 100%; 
  height: auto;
}

.svg-container img {
  width: 100%;
  height: auto;
}

.form-control {
  background-color: #F6E7D8;
  font-family: 'Montserrat', sans-serif;
}

.form-control:focus {
  background-color: #F6E7D8; 
  box-shadow: none;
}

.form-control p {
  color: #091827;
}

.custom-input::placeholder {
  color: #091827;
}


